import { Box, Button, Center, Collapse, Divider, Flex, Heading, IconButton, SimpleGrid, Slide, Stack, Text, Wrap, WrapItem, useColorModeValue } from "@chakra-ui/react";
import ActionButton from "@components/atom/ActionButton";
import TextRenderWithHeading from "@components/atom/TextRenderWithHeading";
import TextRenderWithIcon from "@components/atom/TextRenderWithIcon";
import CancelOrder from "@components/molecules/CancelOrder";
import CustomCollapse from "@components/molecules/CustomCollapse";
import { CaseStatusRender } from "@components/table/CaseListingTableComponent";
import NetworkWrapper from "@components/wrapper/NetworkWrapper";
import { pageView } from "@config/event";
import { usePageTitle, useTitle } from "@hooks/action/useUtils";
import { useGetCase } from "@hooks/api/useCaseApi";
import { useVoiceCall } from "@hooks/api/useDaasApi";
import { Icon } from "@iconify/react";
import sendTrace from "@hooks/api/useSendTracing";
import { useAppStore } from "@store/AppStore";
import { useRedStore } from "@store/store";
import { maskString, TextMaker } from "@utils";
import dayjs from "dayjs";
import { getProperty } from "dot-prop";
import { redEvent } from "lib/red-hooks";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function CaseEnquiry() {
  useTitle("Case Enquiry");
  const loginUser = useAppStore(state => state.loginUser);
  const { id: orderId } = useParams();
  const { data, ...rest }: any = useGetCase({
    orderId,
  });
  const sourceMap = useRedStore(state => state.sourceMap);

  const extractValue = (key: string, defaultVal?: string) => (data?.data && getProperty(data.data.data, key, defaultVal)) || "";
  const darkSlate = useColorModeValue("white", "slate.800");
  const { makeVoiceCall } = useVoiceCall();
  useEffect(() => {
    pageView("Enquiry Page");
  }, []);

  useEffect(() => {
    const eventTracingData = 
    {
      user: { 'email': loginUser?.email ?? '' },
      data:{
        eventName: "HM_CASE_PAGE_OPENED", 
        trace: { caseID: `${orderId}`, page: "CaseEnquiry" }
      }
    }
    sendTrace(eventTracingData)
  },[])

  return (
    <NetworkWrapper status={rest}>
      <Stack pos={"relative"}>
        <CustomCollapse startingHeight="70vh" isOpen={false}>
          <Flex>
            <Box flex="1" mr={[0, 6]}>
              <Wrap w={"full"} justify={"space-between"} bg={darkSlate} align={"center"} px={6} py={4} borderRadius={"md"}>
                <SimpleGrid columns={2} w="full" gap={4}>
                  <TextRenderWithHeading title="Order Id" description={extractValue("orderId")} />
                  <WrapItem maxW={["full", 200]}>
                    <Box>
                      <Text fontSize={"xs"} fontWeight={"normal"} color="blackAlpha.600">
                        Case status
                      </Text>
                      <CaseStatusRender status={extractValue("orderStatus")} />
                    </Box>
                  </WrapItem>
                  <WrapItem maxW={["full", 200]}>
                    <Box>
                      <Text fontSize={"xs"} fontWeight={"normal"} color="blackAlpha.600">
                        Created At
                      </Text>
                      <Text fontSize={"xs"} fontWeight={"semibold"}>
                        {dayjs.unix((extractValue("createdAt") || 1000) / 1000).format("DD MMM YYYY hh:mm A")}
                      </Text>
                    </Box>
                  </WrapItem>
                  <WrapItem maxW={["full", 200]}>
                    <TextRenderWithHeading title="Source Name" description={sourceMap[extractValue("originSource.source")]?.name || "-"} />
                  </WrapItem>
                </SimpleGrid>
              </Wrap>

              <Wrap w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
                <Box w={"full"} mb={"-6"}>
                  <Heading fontWeight={"semibold"} fontSize={"small"}>
                    Dispatch Details
                  </Heading>
                  <Divider my={2} />
                </Box>
                <WrapItem maxW={["full", 200]}>
                  {extractValue("waypoints[0].sourceId") ? (
                    <TextRenderWithIcon
                      icon="material-symbols:location-on-outline"
                      title="Pickup Point"
                      description={sourceMap[extractValue("waypoints[0].sourceId")]?.name}
                      subTitle={TextMaker(extractValue("waypoints[0]"))}
                    />
                  ) : (
                    <TextRenderWithIcon icon="material-symbols:location-on-outline" title="Pickup Point" description={TextMaker(extractValue("waypoints[0]"))} />
                  )}
                </WrapItem>
                <WrapItem maxW={["full", 200]}>
                  {extractValue("waypoints[1].sourceId") ? (
                    <TextRenderWithIcon
                      icon="mingcute:navigation-line"
                      title="Drop"
                      description={sourceMap[extractValue("waypoints[1].sourceId")]?.name}
                      subTitle={TextMaker(extractValue("waypoints[1]"))}
                    />
                  ) : (
                    <TextRenderWithIcon icon="mingcute:navigation-line" title="Drop" description={TextMaker(extractValue("waypoints[1]"))} />
                  )}
                </WrapItem>
                <SimpleGrid columns={2} w={"full"} gap={10}>
                  <WrapItem maxW={200}>
                    <TextRenderWithIcon icon={"mdi:clock-time-three-outline"} title={"Dispatch"} description={extractValue("dispatchType") === "NOW" ? "Immediate" : "Scheduled"} />
                  </WrapItem>
                </SimpleGrid>

                <WrapItem maxW={200}>
                  <TextRenderWithIcon
                    icon={"ep:platform"}
                    title={"Platform"}
                    description={`${extractValue("originSource.platform")} ${extractValue("originSource.version") && `(${extractValue("originSource.version")})`}`}
                  />
                </WrapItem>

                <WrapItem maxW={"full"}>
                  <TextRenderWithIcon icon={"ep:user"} title={"Generated By"} description={extractValue("generatedBy")} />
                </WrapItem>
              </Wrap>

              <Stack w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
                <Box w={"full"} mb={"-6"}>
                  <Heading fontWeight={"semibold"} fontSize={"small"}>
                    Contact Details
                  </Heading>
                  <Divider my={2} />
                </Box>
                <SimpleGrid w={"full"} columns={2} gap={8}>
                  <TextRenderWithIcon icon="mingcute:user-3-fill" title={"Caller Name"} description={extractValue("requestedBy.name")} />
                  <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <TextRenderWithIcon icon={"bi:phone"} title={"Caller Contact Number"} description={maskString(extractValue("requestedBy.mobile"))} />
                    <IconButton
                      borderRadius={"full"}
                      borderWidth={1}
                      variant={"outline"}
                      size={"sm"}
                      width={7}
                      aria-label="Search database"
                      isLoading={rest?.isLoading}
                      isDisabled={!extractValue("requestedBy.mobile")}
                      onClick={() => makeVoiceCall(extractValue("requestedBy.mobile") || "")}
                      icon={<Icon icon={"solar:phone-calling-bold-duotone"} fontSize={20} />}
                      />
                    </Flex>
                  <TextRenderWithIcon icon={"mdi:patient-outline"} title={"Patient Name"} description={extractValue("requestedFor.name")} />
                  <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <TextRenderWithIcon icon="ic:round-local-phone" title={"Patient Contact Number"} description={maskString(extractValue("requestedFor.mobile"))} />
                    <IconButton
                      borderRadius={"full"}
                      borderWidth={1}
                      variant={"outline"}
                      size={"sm"}
                      width={7}
                      aria-label="Search database"
                      isLoading={rest?.isLoading}
                      isDisabled={!extractValue("requestedFor.mobile")}
                      onClick={() => makeVoiceCall(extractValue("requestedFor.mobile") || "")}
                      icon={<Icon icon={"solar:phone-calling-bold-duotone"} fontSize={20} />}
                      />
                    </Flex>
                </SimpleGrid>
              </Stack>

              <Wrap w={"full"} bg={darkSlate} px={6} py={4} mt={6} spacing={10} borderRadius={"md"}>
                <Box w={"full"} mb={"-6"}>
                  <Heading fontWeight={"semibold"} fontSize={"small"}>
                    Patient info
                  </Heading>
                  <Divider my={2} />
                </Box>

                <SimpleGrid w={"full"} columns={2} gap={8}>
                  <TextRenderWithIcon icon="uil:heart-medical" title={"Medical Condition"} description={(extractValue("requestedFor.medicalIssue.reason") || []).join(",")} />
                  <TextRenderWithIcon icon={"mdi:weight-lifter"} title={"Weight"} description={`${extractValue("requestedFor.weight") || "0"} Kg`} />
                  <TextRenderWithIcon
                    icon={"game-icons:body-height"}
                    title={"Height"}
                    description={`${extractValue("requestedFor.height.feet") || 0} ft ${extractValue("requestedFor.height.inches") || 0} inch`}
                  />
                  <TextRenderWithIcon icon="bi:gender-trans" title={"Gender"} description={`${extractValue("requestedFor.gender")}` || "Unknown"} />
                  <TextRenderWithIcon icon="noto:mage" title={"Age"} description={`${extractValue("requestedFor.age") || "Unknown"} Years`} />
                </SimpleGrid>
              </Wrap>
            </Box>
          </Flex>
        </CustomCollapse>

        <Center>
          <ActonSection isDisabled={extractValue("orderStatus")?.toString() === "cancelled" || !extractValue("serviceType")} />
        </Center>
      </Stack>
    </NetworkWrapper>
  );
}

const ActonSection = ({ isDisabled }: { isDisabled: boolean }) => {
  const { id: orderId } = useParams();
  const navigate = useNavigate();

  const onSelect = () => {
    navigate(`/create-case/${orderId}`);
    // navigate(0);
  };

  return (
    <Flex align={"center"} direction={"column"} pos={"relative"}>
      <ActionButton label="Convert to Booking" onClick={onSelect} isDisabled={isDisabled} colorScheme="blackAlpha" />
      <Box pos={"absolute"} bottom={0}>
        <CancelOrder leftIcon={<></>} variant="link" border={0} bg={"transparent"} />
      </Box>
    </Flex>
  );
};
