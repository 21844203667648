import { ILoginUser } from "@config/types"
import { sendTraceAPI } from "api/service/events"

type PropsType = {
  user: { email: string }
  data: { 
    eventName: string
    trace: { page:string, caseID:string }
  }
}

const sendTrace = async (props: PropsType) => {
  try {
    const { user, data } = props
    const payload = {
      serviceName: 'HM_APP',
      traces: [
        user.email,
        data?.trace?.caseID,
        data?.trace?.page
      ],
      eventName: data?.eventName,
      message: JSON.stringify(data?.trace),
      data: data?.trace,
    };
    await sendTraceAPI(payload)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error sending trace:", err)
  }
}

export default sendTrace