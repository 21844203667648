import request from "../request";

const urls = {
  events: (order_id: string) => `/order/${order_id}/events`,
  sendTraceURL: '/tracing/add'
};

export const getEventsForCase = (orderId: string) => {
  const response = request("GET", `${urls.events(orderId)}`);
  return response;
};

export const sendTraceAPI = async (data: any) => {
  const response = await request("POST",`${urls.sendTraceURL}`, data);
  return response;
}
